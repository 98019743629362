import { OrderPage } from "../../pages/OrderPage";
import { PlaceOrderPage } from "../../pages/PlaceOrderPage";
import { AdminChangePasswordPage } from "../../pages/ProfiePage/components";
import { DatabaseMigrationPage } from "../../pages/DatabaseMigrationPage";
import { ProductsPage } from "../../pages/ProductsPage";
import { OrdersPage } from "../../pages/OrdersPage";
import { PaychecksPage } from "../../pages/PaychecksPage";
import { CategorysPage } from "../../pages/CategorysPage";
import { SurveysPage } from "../../pages/SurveysPage";
import { ParcelsPage } from "../../pages/ParcelsPage";
import { PalettesPage } from "../../pages/PalettesPage";
import { FilamentsPage } from "../../pages/FilamentsPage";
import { DashboardPage } from "../../pages/DashboardPage";
import { ExpensesPage } from "../../pages/ExpensesPage";
import { FeaturesPage } from "../../pages/FeaturesPage";
import { CartsPage } from "../../pages/CartsPage";
import { ContentsPage } from "../../pages/ContentsPage";
import ChipsPage from "../../pages/ChipsPage/ChipsPage";
import { AnnouncementEmail } from "../../pages/EmailsPage/components";
import { EmailsPage } from "../../pages/EmailsPage";
import { PromosPage } from "../../pages/PromosPage";
import { AffiliatesPage } from "../../pages/AffiliatesPage";
import { ProfilePage } from "../../pages/ProfiePage";
import { UsersPage } from "../../pages/UsersPage";
import { TutorialsPage } from "../../pages/TutorialsPage";
import { WholesalersPage } from "../../pages/WholesalersPage";
import TeamsPage from "../../pages/TeamsPage/TeamsPage";
import { ImagesPage } from "../../pages/ImagesPage";
import { ChangePasswordPage, PasswordResetPage, ResetPasswordPage } from "../../pages/ProfiePage/components";
import { CartPage } from "../../pages/CartPage";
import { ProductsGridPage } from "../../pages/ProductsGridPage";
import { ProductPage } from "../../pages/ProductPage";
import { FeaturesGridPage } from "../../pages/FeaturesGridPage";
import { FeaturedPage } from "../../pages/FeaturedPage";
import { SponsorsGridPage } from "../../pages/SponsorsGridPage";
import { SponsorPage } from "../../pages/SponsorPage";
import { TeamsGridPage } from "../../pages/TeamsGridPage";
import { TeamPage } from "../../pages/TeamPage";
import { ManualPage } from "../../pages/ManualPage";
import { ColorPalettePage } from "../../pages/ColorPalettesPage";
import SupportCenterPage from "../../pages/SupportCenterPage/SupportCenterPage";
import { TermsPage } from "../../pages/TermsPage";
import { AboutPage } from "../../pages/AboutPage";
import { SitemapPage } from "../../pages/SitemapPage";
import { TutorialsGridPage } from "../../pages/TutorialsGridPage";
import MenuPage from "../../pages/MenuPage/MenuPage";
import EventsPage from "../../pages/EventsPage/EventsPage";
import EventPage from "../../pages/EventPage/EventPage";
import TicketsPage from "../../pages/TicketsPage/TicketsPage";
import ArticlePage from "../../pages/AboutPage/ArticlePage/ArticlePage";
import ArticlesPage from "../../pages/ArticlesPage/ArticlesPage";
import ArticlesGridPage from "../../pages/ArticlesGridPage/ArticlesGridPage";
import AcademyPage from "../../pages/AcademyPage/AcademyPage";

export const Components = {
  PlaceOrderPage,
  OrderPage,
  ChangePasswordPage,
  PasswordResetPage,
  ResetPasswordPage,
  CartPage,
  ProductsGridPage,
  ProductPage,
  FeaturesGridPage,
  FeaturedPage,
  SponsorsGridPage,
  SponsorPage,
  TeamsGridPage,
  TeamPage,
  ManualPage,
  ColorPalettePage,
  SupportCenterPage,
  TermsPage,
  MenuPage,
  AboutPage,
  SitemapPage,
  TutorialsGridPage,
  EventPage,
  ArticlesGridPage,
  ArticlePage,
  AcademyPage,
};
export const AdminComponents = {
  DatabaseMigrationPage,
  ProductsPage,
  OrdersPage,
  UsersPage,
  PaychecksPage,
  CategorysPage,
  SurveysPage,
  ParcelsPage,
  PalettesPage,
  FilamentsPage,
  ProfilePage,
  AdminChangePasswordPage,
  DashboardPage,
  ExpensesPage,
  FeaturesPage,
  CartsPage,
  ContentsPage,
  AnnouncementEmail,
  PromosPage,
  AffiliatesPage,
  TeamsPage,
  ChipsPage,
  TutorialsPage,
  ImagesPage,
  WholesalersPage,
  EmailsPage,
  EventsPage,
  TicketsPage,
  ArticlesPage,
};
export const PrivateComponents = {
  ProfilePage,
  OrderPage,
  PlaceOrderPage,
};
